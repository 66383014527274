* {
  padding: 0;
  margin: 0;
  outline: 0;
}
body {
  width: 100vw;
  height: 100vh;

  background: rgba(255, 255, 255, 0.58);
  font-family: "Poppins", sans-serif;
  top: 0;
  bottom: 0;
}
.logoutside {
  display: none;
}

.Nav__item {
  border-top: 1px solid #d6d1d1;
  border-radius: 10px 10px;
  padding-left: 15px;
}
.Nav__item:hover {
  border-top: 1px solid #081829;
  background: #081829;
  border-radius: 5px;
  padding-left: 15px;
}
.Nav_brand {
  padding: 120px;
  padding-left: 0px;
  position: relative;
  height: 50px;
}
.logo {
  position: absolute;
  width: 140px;
  height: 170px;
  left: 20px;
  top: 15px;
}
.Nav {
  height: 100vh;
  border-radius: 0px 55px 51px 0px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  display: block;
  z-index: 1000;
  font-weight: 200;
  background: #01507a;
  -webkit-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  -moz-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  transition: all 0.3s;
}

.Nav_right .Nav__link {
  text-decoration: none;
  padding: 10px;
  color: #cdddde;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  margin-top: 5px;
  color: #dde2ff;
}
.lebelenterlog {
  display: block;
  font-weight: 600;
  transition: color 0.33s ease;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  padding-top: 5px;
  width: 100%;
  font-size: 14px;
  color: #ffffff;
}
.formModaltes {
  appearance: none;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  color: inherit;
  font-weight: 500;
  inline-size: 100%;
  margin: 0;
  padding: 15px;
  text-overflow: ellipsis;
}
.dropdown-menu {
  position: absolute;
  top: 0;
  right: 100px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.ullinks {
  list-style-type: none;
  padding: 20px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
}
.ullinks i {
  color: #fff;
  font-size: 16px;
  line-height: 50px;
}
.welcome {
  padding-top: 20px;
}
.newside {
  border-radius: 10px;
  height: 264px;
  width: 531px;
}
.news {
  border-radius: 10px;
  height: 264px;
  width: 431px;
  margin-left: 30px;
}

.cardphoto {
  background: #ffffff;
  box-shadow: 0px 36px 145px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: none;
  width: 380px;
  margin-left: 105px;
  padding: 10px;
  margin-top: -20px;
}
.tslog {
  font-size: 34px;
  padding-top: 10px;
  line-height: 44px;
  color: #ffffff;
}

.table-wrapper {
  width: 100%;
}

.table-container {
  width: 100%;
}
.stucont {
  padding-bottom: 30px;
}

.table-row-head {
  margin-top: 10px;
  text-align: left;
  border-radius: 10px;
  border-top: 10px solid #f7f7f7;
}

.table-rows {
  background: rgba(205, 221, 222, 0.2);
  margin-top: 10px;
  border-radius: 10px;
  border-top: 10px solid #f7f7f7;
}

.table-row {
  background: rgba(205, 221, 222, 0.2);
  margin-top: 10px;
  border-radius: 10px;
  font-size: 12px;
  text-align: left;
  border-top: 10px solid #f7f7f7;
  /* color: #106eea; */
}

.table-head {
  color: #106eea;
}

.table-head {
  color: #106eea;
}

.table-container th {
  padding: 5px 10px;
}

.table-row td {
  padding: 3px 10px;
}

.titleceo {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #1c2834;
}
.name {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #00cc6a;
}
.cardworklistwork {
  border-top: 5px solid #584d17;
  background: #ffffff;
  object-fit: fill;
  width: 100%;
  box-shadow: 0px 0px 377px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-right: 40px;

  /* margin-top: 30px;
  object-fit: fill;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  width: 105%; */
}

.cardworklist {
  width: 100%;
  margin-left: 40px;
}
.profile-headerstu {
  display: flex;
}
.userprofile {
  height: 60px;
  width: 55px;
  cursor: pointer;
  background: #f4f5f6;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 20px 0px 20px;
}

.publish {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  letter-spacing: 0.2px;
  padding-left: 40px;
  color: #584d17;
}
.detoal {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding-left: 100px;
  cursor: pointer;
  letter-spacing: 0.2px;
  color: #584d17;
}
.btnsign {
  background: #ffff;
  color: #00cc6a;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  height: 50px;
  padding: 0px 40px;
  border: 1px solid #00cc6a;
  border-radius: 3px;
  margin-left: 40%;
  cursor: pointer;
}

.succpar {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: rgba(84, 89, 94, 0.6);
  flex: none;
  order: 2;
  flex-grow: 0;
}
.detoal:hover {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding-left: 100px;
  cursor: pointer;
  letter-spacing: 0.2px;
  color: #061627;
}
.searchexp {
  display: flex;
  /* padding-left: 70%; */
  justify-content: end;
}
.cardtable {
  background: #ffffff;
  padding: 20px;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
}
.categoryhah {
  background: #f4f5f6;
}
.dataprofile {
  margin-top: 40px;
}
.datacoin {
  padding-top: 5px;
  padding-left: 20px;
}
.coinprofile {
  height: 60px;
  width: 55px;
  cursor: pointer;
  /* background: #f4f5f6; */
  border-radius: 10px;
  /* padding: 10px;
  margin: 40px 40px 0px 40px; */
}
.userproname {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  color: #252733;
}
.userpron {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #252733;
}
.linecomplist {
  border: 0.5px solid #0f0f0f1e;
  width: 100%;
  margin-top: -2px;
}
.userpro {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  margin-top: -14px;
  color: #000000;
}
/* .Nav_right .Nav__link:hover,
.active {
  transition: all 0.5s;
  background-color: white;
  border-radius: 49px 0px 0px 60px;
  padding-left: 20px;
  color: #061627;
} */
.Nav__item {
  display: flex;
  justify-items: space-between;
}
.main {
  margin-top: 20vh;
}
.page-wrapper .sidebar-wrapper {
  width: 220px;
  height: 100%;
  /* background: #7c6d5f; */
  background: linear-gradient(180deg, #584d17, #444342);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: left 0.3s ease, width 0.3s ease;
  border-radius: 0px;
}
.logdashboard {
  color: #fff;
  margin-top: 50px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
}
.sidebar-title {
  width: 200px;
  text-align: center;
  margin-left: 20px;
  margin-top: 40px;
  color: #fff;
  /* background: rgba(205, 221, 222, 0.67); */
  border-radius: 39px 0px 0px 39px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 19px;
}
.logo- {
  margin-top: 20px;
  height: 60px;
  border-radius: 50px;
}
.sidebar-title i {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 20px;
}
/* .contentdashbord {
  margin-left: 40px;
} */
.login {
  padding-top: 85px;
  padding-left: 20px;
  height: 100vh;
  width: 100vw;
  background: #444342;
}

.caerdlog {
  top: 50px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  /* height: 60%; */
  padding: 30px;
  /* background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08); */
  border-radius: 20px;
}
.btnapplsign {
  background: linear-gradient(180deg, #baa85a, #41362d);
  border-radius: 5px;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  inline-size: 100%;
  line-height: 44px;
  padding: 3px 50px;
  border: none;
  cursor: pointer;
}
.logintitle {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  font-size: 35px;
  line-height: 42px;
  color: #000000;
}
.addinfo {
  margin-left: auto;
  float: right;
}
.addin {
  margin-top: 50px;
}
.addSchool {
  background: #081829;
  border-radius: 12px;
  color: #fff;
  padding: 6px;
  width: 145px;
  margin-left: 20px;
  border: none;
}
.addnewbus {
  background: #081829;
  border-radius: 12px;
  color: #fff;
  padding: 6px;
  width: 175px;
  margin-left: 20px;
  border: none;
}
.profile {
  border: 50px;
  background-color: #91823f;
  color: #fff;
  height: 40px;
  width: 40px;
  font-size: large;
  font-size: 1em;
  margin-left: 20px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}
.searching {
  border: 50px;
  background-color: #fea104;
  color: #fff;
  height: 40px;
  width: 40px;
  font-size: large;
  font-size: 1em;
  margin-left: 20px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}
.username {
  padding-left: 10px;
  margin-top: -6px;
}
.busesoverl {
  /* padding-top: 10px; */
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 16px; */

  color: rgba(63, 63, 63, 0.96);
}
.rowpush {
  margin-top: -30px;
  margin-bottom: -80px;
}
.rowup {
  margin-top: 10px;
}

.contentmemb {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  color: #2d2d2e;
}
.schooloverl {
  padding-left: 10px;
  float: right;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: rgba(63, 63, 63, 0.96);
}
.cardrow {
  margin-right: 0px;
  margin-top: 5px;
}

.rowbusoverall {
  background: rgba(205, 221, 222, 0.2);
  backdrop-filter: blur(15px);
  padding: 10px;
  margin-top: 20px;
  width: 380px;
  border-radius: 15px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.rowschooll {
  background: rgba(205, 221, 222, 0.2);
  backdrop-filter: blur(15px);
  /* padding: 10px; */
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.rowscomp {
  background: #f29800;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 17px;
  padding: 15px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
}
.rowspro {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  background: rgba(242, 152, 0, 0.15);
  border-radius: 20px;

  padding: 15px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
}
.rowsco {
  padding: 15px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
  height: 55px;
}
.rowsdet {
  height: 50px;
}
.rowswtdet {
  border: 1px solid rgba(205, 221, 222, 0.36);
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  border-radius: 10px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 55px;
}
.contrilink {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-left: 0px;
  padding-bottom: 20px;
  color: #4a4a4b;
  cursor: pointer;
}
.contrilink:hover {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  line-height: 16px;
  margin-left: 0px;
  padding-bottom: 20px;
  color: #00cc6a;
  cursor: pointer;
}
.userprony {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  background: linear-gradient(180deg, #baa85a, #7c6d5f);
  font-size: 12px;
  color: #ffffff;
}
.rowsdetcomp {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  padding: 3px;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  height: 55px;
}
.rowsdet .rowbusoveralltitle {
  margin-left: 10px;
  font-family: " Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #3f3f3f;
}
.contentdetcomp {
  color: #fff;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}
.contentdetpro {
  color: #fea104;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
}
.contentdet {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 15px;
  line-height: 24px;
  padding: 10px;
  margin-top: 12px;
  color: #3f3f3f;
}
.contedertbox {
  color: #3f3f3f;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
}

.nbrbus {
  margin-left: 30px;
  font-family: " Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #777777;

  line-height: 24px;
}
.nbrschool {
  margin-left: 30px;
  font-size: 15px;
  font-family: " Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;

  line-height: 24px;
}

.tranctionheader {
  float: right;
  font-size: 12px;
}
.schheader {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #3f3f3f;
}
.infoheader {
  margin-left: 40px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #3f3f3f;
}
.overlaysum {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 10px;
}
.caerdui {
  background: linear-gradient(180deg, #584d17, #444342);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-left: 2px;
  width: 100%;
}
.caerd {
  background: linear-gradient(180deg, #baa85a, #7c6d5f);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-left: 2px;
}
.cardsum {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-left: 2px;
}
.carddetails {
  background: transparent;
  border-radius: 8px;
  width: 105%;
  height: 120px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.26);
  color: #000000;
  margin-right: 8px;
  border: 1.5px solid #584d17;
}
.carddeta {
  background: #081829;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  color: #fff;
  margin-left: 25px;
}

.newschool i {
  background: rgba(255, 255, 255, 0.58);
  backdrop-filter: blur(15px);
  border-radius: 9px;
  height: 40px;
  width: 40px;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: 2px;
  font-size: 20px;
}
.pgschl {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #cdddde;
  margin-top: -9px;
  margin-left: 10px;
}
.pgnbschl {
  font-size: 24px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  color: #fff;
  margin-top: -10px;
  margin-left: 10px;
}
.pg1schl {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #cdddde;
  margin-left: 10px;
}
.imgbus {
  height: 190px;
}
.dangerdemor {
  color: #f29800;
  text-align: center;
}
.overlay {
  position: absolute;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 10px;
}
.detailsbus1 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #424646;
  padding: 10px;
  text-align: center;
}
.detailsbusnofond {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10.5px;
  line-height: 15px;
  padding: 10px;
  margin-top: 5px;
  color: #ff0000;
}
.detailsbus {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  padding: 10px;
  margin-top: 5px;
  color: #323232;
}
.detailsbu {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  padding-top: 10px;

  color: #424646;
}
.imgkaty {
  padding-top: 5px;
  height: 50px;
  width: 50px;
  border: 100%;
}
.rowsdet {
  background: rgba(205, 221, 222, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 5px;
  padding: 0px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.rowcodet {
  padding: 5px;
  margin-top: 2px;
  margin-left: 5px;
}
.headercomp {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  margin-left: 20px;
  color: #081829;
}
.imgparahead {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-left: 5px;
  /* display: block; */
  line-height: 19px;
  color: #fff;
}

.logininputsselectyu {
  cursor: pointer;
  padding: 5px;
  color: #000000;
  font-size: 13px;
  font-weight: 800;
  background-color: transparent;
  background-image: none;
  border: none;
  border-radius: 14px;
}
.imgparahe {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 19px;
  color: #dee0d979;
}
.imgparabod {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  color: #000000;
  display: block;
}
.imgpara {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 27px;
  color: #323232;
  display: block;
  margin-left: 20px;
}
.imgparabodyy {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #d11a2a;
  display: block;
}
.imgparabodyi {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #00cc6a;
  display: block;
}
.imgparabody {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #ffffff;
  display: block;
}
.imginfo {
  margin-top: -10px;
  padding: 20px;
}
.imginfog {
  padding-left: 30px;
}
.headercompdeta {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  padding-bottom: 10px;
  color: #081829;
}
.detailsdest {
  background: rgba(254, 161, 4, 0.19);
  backdrop-filter: blur(18px);
  border-radius: 9px;
  border: 0px;
  text-align: center;
  padding: 5px 15px;
  margin-top: 10px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
}
.colcard {
  background: #ffffff;
  /* background: linear-gradient(180deg,#baa85a,#41362d); */
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11); */
  border-radius: 10px;
}
.coltrack {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
}
.Busplate {
  background: #ffffff;
  border-radius: 15px;
  border: 0;
  padding: 10px;
  margin-left: 20px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
}

.selectcomp {
  color: #829293;
}
.Busplate::placeholder {
  color: #000000;
}
.user {
  margin-top: -40px;
}
.itemok {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.item {
  display: flex;
  border-bottom: 1px solid #dfe0eb;
}
.viewdet {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: auto;
  line-height: 40px;
  color: #00cc6a;
}
.viewdetnum {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 15px;
  line-height: 20px;
  margin-left: auto;
  color: #9fa2b4;
}
.viewdetint {
  margin-left: auto;
  color: #ffffff;
  padding: 5px 20px;
  background: #fec400;
  border-radius: 8px;
  cursor: pointer;
}
.viewedit {
  margin-left: 25px;
  color: #ffffff;
  margin-top: 15px;
  padding: 5px 20px;
  background: #00cc6a;
  border-radius: 5px;
  cursor: pointer;
}

.ansewq {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
  color: #00cc6a;
}
.ques {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.asktit {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 50px;
  line-height: 150px;
  color: #000000;
}
.bodyasky {
  text-align: center;
  padding: 35px;
  display: flex;
  border: 3px solid #dedede;
  border-radius: 6px;
  margin-bottom: 20px;
}
.viewdpubl {
  border: 2px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  color: #000000;
  margin-top: 15px;
  padding: 5px 20px;
}
.newsectionadd {
  padding: 5px;
}
/* .chofile {
  font-size: 7px;
} */
.chofile {
  position: absolute;
  opacity: 0;
}
.btnaddjob {
  font-size: 7px;
  padding-top: 15px;
  /* padding-left: 7px; */
  width: 50px;
  height: 50px;
  color: #ffff;
  text-align: center;
  background: linear-gradient(180deg, #584d17, #444342);
  border-radius: 50px;
  cursor: pointer;
}
.btnaddjob i {
  color: #fff;
  margin-left: 15px;
  font-size: 20px;
  cursor: pointer;
}
.btnaddblog {
  align-self: center;
  font-size: 18px;
  padding: 6px 10px;
  width: 40px;
  height: 40px;
  color: #ffff;
  text-align: center;
  background: #584d17;
  border-radius: 50px;

  margin: 10px;
  cursor: pointer;
  cursor: pointer;
}
.viewedel {
  margin-left: 10px;
  color: #ffffff;
  margin-top: 15px;
  padding: 5px 20px;
  background: #d11a2a;
  border-radius: 5px;
  cursor: pointer;
}
/* .viewdetcli {
  margin-left: auto;
  color: #ffffff;
  margin-top: 15px;
  padding: 5px 20px;
  background: #202a4d;
  border-radius: 8px;
  cursor: pointer;
} */
.viewdetclionme {
  color: #ffffff;
  font-size: 12px;
  margin-right: 10px;
  font-family: sans-serif;
  padding: 5px 10px;
  float: right;
  border: none;
  background: linear-gradient(180deg, #baa85a, #7c6d5f);
  border-radius: 10px;
  cursor: pointer;
}
.product {
  height: 40px;
  cursor: pointer;
}
.viewdetclionbtn {
  color: #ffffff;
  font-size: 10px;
  margin-top: 15px;
  margin-left: 40%;
  font-family: sans-serif;
  padding: 10px 15px;
  background: linear-gradient(180deg, #baa85a, #7c6d5f);
  border-radius: 3px;
  border: none;
  cursor: pointer;
}
.activejobs {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 42px;
  letter-spacing: 0.2px;
  color: #081829;
}
.viewdetclion {
  color: #ffffff;
  font-size: 10px;
  margin-top: 10px;
  text-align: center;
  border: none;
  /* margin-left: 20px; */
  padding: 2px 2px;
  width: 50px;
  background: linear-gradient(180deg, #baa85a, #7c6d5f);
  border-radius: 50px;
  cursor: pointer;
}
.viewdetapp {
  margin-left: auto;
  cursor: pointer;
  color: #9fa2b4;
  margin-top: 15px;
  padding: 5px 20px;
  background: #f0f1f7;
  border-radius: 8px;
}
.todayha {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9fa2b4;
}
.itemtitle {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  padding-top: 15px;
  color: #252733;
}

.logout {
  background: #584d17;
  border-radius: 12px;
  margin-left: 20px;
}
.logout:hover {
  background-color: #584d17;
  border: 1px solid #584d17;
}
.iconlogout i {
  font-size: 30px;
  margin-left: 20px;
}
.Map {
  position: relative;
  background: rgba(255, 255, 255, 0.58);
  backdrop-filter: blur(15px);
  border-radius: 9px;
}
.rowmapnext {
  top: 395px;
  position: relative;
  margin-right: 20px;
}
.summa {
  text-align: end;
  color: #ffffff;
}
.trackcontent {
  margin-top: 400px;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-left: 15px;
  padding: 30px;
  padding-bottom: 20px;
}
.seachbt {
  float: left;
}

.trackmap {
  margin-right: 10px;
  border-radius: 10px;
  padding-top: 10px;
}
.imagemen {
  width: 50px;
  height: 50px;
}
.locationcircle {
  width: 45px;
  margin-left: 7px;
  height: 45px;
  text-align: center;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 1px 0 rgb(255 255 255 / 20%);
  border-radius: 50%;
  background: rgba(254, 161, 4, 0.19);
  backdrop-filter: blur(18px);
}
.locationcircle i {
  color: #fea104;
  font-size: 30px;
  padding: 5px;
}
.cardboxes {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  padding: 20px;
}
.peacetitle {
  text-align: center;
  /* padding-top: 15px; */
  background: linear-gradient(180deg, #91823f, #635950);
  box-sizing: border-box;
}
.peacepara {
  text-align: center;
  padding-top: 20px;
  color: #fff;
}
.peacepic {
  width: 80px;
  height: 80px;
}
.peaceimg {
  text-align: center;
  margin-top: -40px;
}
.stus {
  font-size: 8px;
  color: rgba(63, 63, 63, 0.96);
}
.in i {
  float: right;
  margin-right: 10px;
}
.skltit {
  padding-left: 50px;
}
.searchoverall {
  padding: 13px 15px;
  margin-left: 210px;
  color: #333333;
  line-height: 20px;
  height: 45px;
  background: #ffffff;
  border-radius: 15px;
  border: 0;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  box-shadow: 0 0 15px #e5e9ec;
  border-bottom: 1px solid #d7dbe2;
}

.loginsearchfa {
  height: 40px;
  /* width: 20em; */
  font-size: 14px;
  color: #323232;
  margin: 10px;
  padding: 20px 15px;
  line-height: 20px;
  border: 1px solid rgb(204, 194, 194);
  border: 0;
  background: #f1f8ff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 10px;
  box-shadow: 0 0 5px #e5e9ec;
  border: 1px solid #d7dbe2;
}

.contper {
  margin-left: 10px;
  display: block;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  /* margin: 50px; */
  width: 90%;
  border-top: 1px solid #e1d8d8;
  border-bottom: 1px solid #e1d8d8;
  /* border-left: 1px solid #e1d8d8; */
}
.contsal {
  display: block;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  /* margin: 50px; */
  width: 90%;
  border-top: 1px solid #e1d8d8;
  border-bottom: 1px solid #e1d8d8;
  border-left: 1px solid #e1d8d8;
}
.contcount {
  margin-left: 50px;
  display: block;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  /* margin: 50px; */
  width: 90%;
  border-top: 1px solid #e1d8d8;
  border-bottom: 1px solid #e1d8d8;
  border-left: 1px solid #e1d8d8;
}

.activejobs {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 0.2px;
  color: #000000;
}

.descr {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #3b3819;
}
.numbery {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  font-size: 18px;
  color: #081829;
}
.nuber {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  font-size: 16px;
  color: #000000;
}
.number {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  font-size: 18px;
  color: #000000;
}
.tooglebutton {
  border: 0;
  margin-left: 10px;
}
.toggle i {
  color: #081829;
  font-size: 25px;
  position: fixed;
}
@media (max-width: 768px) {
  .tooglebutton {
    margin-top: 10px;
    border: 0;
    font-size: 25px;
    margin-right: 30px;
    float: right;
  }
  .logoutside {
    display: block;
    text-decoration: none;
    padding: 10px;
    color: #cdddde;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    border: none;
    cursor: pointer;
    font-size: 13px;
    background-color: transparent !important;
    color: #dde2ff;
  }
  .toggle i {
    color: #081829;
    font-size: 25px;
    float: right;
  }
  .tooglebutton {
    position: fixed;
    top: 2px;
    right: 2rem;
    z-index: 999999;
  }
  .header-items {
    max-width: 100%;
    margin-right: 50px;
    margin-bottom: 40px;
  }
  .caerdui {
    background: linear-gradient(180deg, #584d17, #444342);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    margin-left: 100%;
    margin-top: 40px;
    width: 150%;
  }
  .rowpush {
    margin-top: 30px;
    margin-bottom: -90px;
  }
  .buttoncenter {
    margin: 8px;
    margin-top: 40px;
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateY(-50%);
  }
  .caerdlog {
    top: 50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* height: 60%; */
    padding: 30px;
    border-radius: 20px;
  }
}
@media (max-width: 500px) {
  .caerdui {
    background: linear-gradient(180deg, #584d17, #444342);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    margin-left: 30px;
    margin-top: 40px;
    width: 300%;
  }
  .header-items {
    max-width: 100%;
    display: block;
    margin-left: 50px;
    margin-bottom: 40px;
  }
}
/* .page-wrapper .sidebar-wrapper {
  height: 100%;
  background: #1c2834;
  backdrop-filter: blur(15px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;

} */
.page-wrapper.enlarged .page-content {
  padding-left: 0;
}
.page-wrapper.enlarged .sidebar-wrapper {
  width: 0;
}
.page-wrapper.enlarged .sidebar-title {
  display: none !important;
}
.hedsea {
  margin-left: 200px;
  margin-top: -40px;
}
.pagi {
  color: #e5e9ec;
}
.closeModal {
  border: 50px;
  background: #ffffff;
  height: 20px;
  width: 20px;
  float: right;
  font-size: large;
  font-size: 1em;
  margin-left: 20px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}
.closeModal i {
  font-size: 15px;
  color: #061627;
}

.addschoolmod {
  color: #ffffff;
  padding: 20px 0px 10px 50px;
}
.Madl {
  border-radius: 12px;
  background: #eff1f5;
  border-radius: 10px;
  /* width: 45%; */
}
.contentmemb {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  padding: 20px;
  color: #2d2d2e;
}
.conteinputitle {
  background: linear-gradient(180deg, #baa85a, #7c6d5f);
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}
.logininputsele {
  display: block;
  width: 15em;
  height: 10px;
  margin-left: 2em;
  margin-bottom: 25px;
  padding: 15px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgb(129, 143, 163);
  border-radius: 14px;
}
.viewdetcli {
  color: #ffffff;
  font-size: 14px;
  font-family: sans-serif;
  padding: 10px 20px;
  background: linear-gradient(180deg, #baa85a, #7c6d5f);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.conteinpu {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  color: #4a4a4b;
}
.closeModale {
  border: 50px;
  background: #ffffff;
  height: 20px;
  width: 20px;
  margin-top: 7px;
  float: right;
  font-size: large;
  font-size: 1em;
  margin-right: 10px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}

.superball {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #000000;
}
.succpar {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;

  color: #000000;
}
.modal-body {
  position: fixed;
  right: auto;
  left: auto;
  top: 50px;
  /* height: 400px; */
  background: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
}
.btnhome {
  padding: 25px;
}
.btnbook {
  color: #202a4d;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border: 3px solid #7c6d5f;
  background: transparent;
  line-height: 44px;
  padding: 3px 40px;
  cursor: pointer;
  border-radius: 3px;
}
.btnd {
  color: #121213;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin: 10px;
  background: transparent;
  border: 1px solid #b3a835;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 7px;
  /* border: 1px solid #d7dbe2; */
}
.btnbookr {
  color: #fff;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border: 2px solid #fff;
  background: transparent;
  line-height: 34px;
  margin-right: 5px;
  padding: 3px 30px;
  cursor: pointer;
  border-radius: 3px;
}
.form-file {
  visibility: hidden;
}
.btnappl {
  background: linear-gradient(180deg, #584d17, #7c6d5f);
  color: #ffffff;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 44px;
  padding: 6px 40px;
  border: none;
  border-radius: 3px;
  margin-left: 20px;
  cursor: pointer;
}
.formModalase {
  display: block;
  padding: 5px 15px;
  line-height: 20px;
  width: 85%;
  height: 120px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  color: #555;
  background: #ffffff;
  -moz-border-radius: 50px;
}
.formModalbus {
  display: block;
  padding: 5px 15px;
  line-height: 20px;
  width: 90%;
  height: 50px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  color: #555;
  background: #ffffff;
  -moz-border-radius: 50px;
}
.formModal {
  display: block;
  padding: 5px 15px;
  line-height: 20px;
  width: 302px;
  height: 45px;
  border: 0.6px solid #b1b1b1;
  border-radius: 6px;
  font-size: 14px;
  color: #555;
  background: #ffffff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 10px;
}
.formModalalt {
  display: flex;
  padding: 5px;
  margin-top: -10px;
  border: 0;
  font-size: 14px;
  color: #555;
  background: #ffffff;
}
.formdata {
  margin-top: 40px;
  margin-left: 30px;
  margin-right: auto;
}
.claseleee {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #263a43;
}
.lebelenter {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  margin-left: 25px;
  color: #263a43;
}
.labels {
  position: relative;
  margin-left: 30px;
  top: 20px;
  background-color: #061627;
  color: #cdddde;
}
.apmodal {
  padding-top: 30px;
  padding-right: 100px;
}
.btnmodal {
  background: #fea104;
  border-radius: 4px;
  border: 0;
  color: #fff;
  position: absolute;
  top: 132%;
  width: 90%;
  right: auto;
  left: auto;
  padding: 10px;
  font-size: 16px;
}
.addreportbuton {
  margin-top: 12%;
  padding: 8px;
  font-size: 16px;
  background: #fea104;
  border-radius: 4px;
  width: 90%;
  border: 0;
  right: auto;
  left: auto;
}
.paginationcl {
  padding: 20px;
}
.btnbookrermore {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  color: #081829;
  margin-top: 10px;
  border: 2px solid #7c6d5f;
  background: linear-gradient(180deg, #baa85a, #7c6d5f);
  background: transparent;
  line-height: 23px;
  padding: 5px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 800;
}
.buttoncenter {
  margin: 8px;
  margin-top: 40px;
  position: absolute;
  text-align: center;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
